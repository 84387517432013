import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDropFiles]'
})
export class DropFilesDirective {
  @Output() fileDropped = new EventEmitter<File[]>();

  @HostListener('drag', ['$event'])
  @HostListener('dragenter', ['$event'])
  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  @HostListener('dragstart', ['$event'])
  @HostListener('dragover', ['$event'])
  @HostListener('dragexit', ['$event'])
  @HostListener('drop', ['$event'])
  preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public ondrop(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer && e.dataTransfer.files) {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
