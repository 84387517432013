import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.styl'],
})
export class LayoutComponent implements OnInit {
  @Input() showLogo = true;
  @Input() pos = '3';

  constructor() {}

  ngOnInit() {}
}
