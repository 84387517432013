import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'section-item-single-cert',
  templateUrl: './section-item-single-cert.component.html',
  styleUrls: ['./section-item-single-cert.component.less']
})
export class SectionItemSingleCertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
