import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DocService } from '../../services/doc.service';


@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.styl'],
  encapsulation: ViewEncapsulation.None
})
export class DocComponent implements OnInit {
  constructor(public doc: DocService) { }

  ngOnInit() {
  }
}
