import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ISettingsContacts } from '../../types/Entities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.styl'],
})
export class FooterComponent implements OnInit {
  @Input() skin = 'none';
  @Input() theme = '1';
  @Input() gap = '1';
  public d: Date = new Date();
  public contacts: ISettingsContacts = {
    email: 'support@lastick.ru',
    phone: '8 495 120 42 64',
    time: '8:00-23:00'
  };
  public formattedPhone: string;

  constructor(
    private global: GlobalService
  ) {}

  ngOnInit() {
    this.contacts = this.global.settings.widget_settings.contacts || this.contacts;
    this.formattedPhone = this.contacts.phone
      .replace(/\s/g, '')
      .replace(/^8/, '+7');
  }
}
