import { Component, Input, OnInit } from '@angular/core';
import {ICertificateSkinSettings} from '../../../types/Entities';
import {SocialService} from '../../../services/social.service';

@Component({
  selector: 'app-header-share',
  templateUrl: './header-share.component.html',
  styleUrls: ['./header-share.component.styl']
})
export class HeaderShareComponent implements OnInit {
  @Input() skin: ICertificateSkinSettings;
  @Input() theme = '1';
  public showNav = false;

  constructor(
      public social: SocialService
  ) { }

  ngOnInit() {
  }

}
