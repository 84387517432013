import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.styl']
})
export class StatusMessageComponent implements OnInit {
  @Input() message: string;
  @Input() status: 'error' | 'changed' = 'error';

  constructor() { }

  ngOnInit() {
  }

}
