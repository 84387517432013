import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { TabsTabComponent } from './tabs-tab/tabs-tab.component';
import { get as getCookie, set as setCookie } from 'js-cookie';
import { addMinutes } from 'date-fns';
import { Subject } from 'rxjs';
import { IItem } from '../scroll/scroll.component';
import { ICertificateSkinSettings } from '../../types/Entities';
import {PlatformService} from '../../services/platform.service';

interface IStorageData {
  tab: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.styl'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabsTabComponent) tabs: QueryList<TabsTabComponent>;
  @Output() showHideTabs: EventEmitter<any> = new EventEmitter();
  @Output() showTabs: EventEmitter<any> = new EventEmitter();
  @Input() minh: number;
  @Input() theme = '1';
  @Input() sz = '1';
  @Input() headMb = '1';
  @Input() btnItemMr = '1';
  @Input() uuid = 'tabs_uuid';
  @Input() store = true;
  @Input() storageKey = 'tabs';
  @Input() storageExpires: Date;
  @Input() activeTab: string;
  @Input() headGap = 'none';
  @Input() bodyGap = 'none';
  @Input() skin: ICertificateSkinSettings = {skin: 'none'};

  public itemClick$ = new Subject<IItem>();

  constructor(
    private platform: PlatformService
  ) {}

  private get storeExpires(): Date {
    return this.storageExpires || addMinutes(new Date(), 30);
  }

  ngAfterContentInit() {
    this.uuid = this.uuid || 'tabs_uuid';
    this.storageKey = `${this.storageKey}_${this.uuid}`;
    let storageData;
    let storage: IStorageData;
    if (this.platform.isBrowser) {
      storageData = getCookie(this.storageKey);
      storage = storageData ? JSON.parse(storageData) : null;
    }
    const activeTab = this.activeTab ? this.tabs.find(t => t.uuid === this.activeTab) :
      storage ? this.tabs.find(t => t.uuid === storage.tab) : this.tabs.first;
    Promise.resolve(null).then(() => {
      this.selectTab(activeTab, false);
    });
  }

  saveToStorage(tab: string) {
    if (this.store && this.platform.isBrowser) {
      setCookie(
        this.storageKey,
        JSON.stringify({
          tab,
        } as IStorageData),
        { expires: this.storeExpires }
      );
    }
  }

  selectTab(tab, emit = true) {
    this.tabs.toArray().forEach(_tab => {
      if (_tab.show$.getValue()) {
        _tab.showHide.emit({ value: false, data: _tab.data });
        if (emit) {
          this.showHideTabs.emit({ value: false, data: _tab.data });
        }
        _tab.hide.emit(_tab.data);
        _tab.show$.next(false);
      }
    });
    if (tab && !tab.show$.getValue()) {
      tab.show$.next(true);
      tab.showHide.emit({ value: true, data: tab.data });
      tab.show.emit(tab.data);
      if (emit) {
        this.showHideTabs.emit({ value: true, data: tab.data });
        this.showTabs.emit(tab.data);
      }
      this.saveToStorage(tab.uuid);
    }
  }

  onTabClick(e: Event, tab) {
    this.selectTab(tab);
    // this.itemClick$.next({$: e.currentTarget as HTMLElement});
  }
}
