import { Injectable } from '@angular/core';
import { IShowcaseOrderItem } from '../types/req';
import {PlatformService} from './platform.service';

export type TEcommerceAction = 'purchase' | 'add';

export interface IEcommerceProduct {
  id?: string;
  name?: string;
  list_name?: string;
  brand?: string;
  category?: string;
  variant?: string;
  list_position?: number;
  quantity?: number;
  price?: string | number;
}

export interface IEcommerceActionFieldObject {
  id: string;
}

export interface IEcommerceActionFieldPurchase {
  transaction_id?: string;
  affiliation?: string;
  value?: number;
  currency?: string;
  tax?: number;
  shipping?: number;
  items?: IEcommerceProduct[];
}

export interface IEcommerceActionField extends IEcommerceActionFieldPurchase {
  items?: IEcommerceProduct[];
}

export interface IEcommerceActionFieldYandex {
  actionField?: IEcommerceActionFieldObject;
  products?: IEcommerceProduct[];
}

export interface IEcommerceActionYandex {
  purchase?: IEcommerceActionFieldYandex;
  add?: { products: IEcommerceProduct };
}

export interface IEcommerceObjectFieldYandex extends IEcommerceActionYandex {
  currencyCode?: string;
}

export interface IEcommerceObjectYandex {
  ecommerce: IEcommerceObjectFieldYandex;
}

@Injectable({
  providedIn: 'root',
})
export class MetricService {
  private dataLayer: IEcommerceObjectYandex[];
  private gtag: (arg1, arg2, arg3) => void;
  private fbq: (arg1, arg2, arg3) => void;

  constructor(
    private platform: PlatformService
  ) {
    this.gtag = (window as any).gtag;
    this.dataLayer = (window as any).dataLayer;
    this.fbq = (window as any).fbq;
  }

  ecommmerceGtagPurchase(data: IEcommerceActionFieldPurchase, items: {showcase?: IShowcaseOrderItem[]}) {
    if (!this.gtag || !this.platform.isBrowser) return;
    const _data: IEcommerceActionFieldPurchase = {
      // shipping: 0,
      currency: 'RUB',
      affiliation: 'Zapomni.Gift',
      ...data,
    };
    if (items.showcase) {
      _data.items = this.reduceShowcaseOrderItems(items.showcase);
    }
    this.gtag('event', 'purchase', _data);
  }

  ecommmerceYandexPurchase(data: IEcommerceActionFieldPurchase, items: {showcase?: IShowcaseOrderItem[]}) {
    if (!this.dataLayer || !this.platform.isBrowser) return;
    const _data: IEcommerceObjectYandex = {
      ecommerce: {
        purchase: {
          actionField: {
            id: data.transaction_id
          },
          products: [
          ]
        }
      }
    };
    if (items.showcase) {
      _data.ecommerce.purchase.products = this.reduceShowcaseOrderItems(items.showcase);
    }
    this.dataLayer.push(_data);
  }

  trackFb(event, data: {value?: string | number, currency?: string}, type?) {
    if (!this.fbq || !this.platform.isBrowser) return;

    this.fbq(type || 'track', event, {
      currency: 'RUB',
      ...data
    });
  }

  reduceShowcaseOrderItems(items: IShowcaseOrderItem[]): IEcommerceProduct[] {
    return items.reduce((acc, item) => {
      acc.push({
        id: item.number,
        name: (item.name || {}).ru,
        quantity: 1,
        price: item.amount / 100
      });
      return acc;
    }, [] as IEcommerceProduct[]);
  }
}
