import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

export interface IImgListenerLoad {
  size: {w: number, h: number, clientW: number, clientH: number};
}

@Directive({
  selector: '[appImgListener]'
})
export class ImgListenerDirective implements OnInit {
  @Output() instanceLoad: EventEmitter<IImgListenerLoad> = new EventEmitter<IImgListenerLoad>();

  private imgInstance = new Image();
  constructor(private elRef: ElementRef) { }

  @HostListener('load', ['$event'])
  onLoad(e: Event | any) {
    this.imgInstance.src = e.target.src;
  }

  ngOnInit(): void {
    this.addListener();
  }

  addListener() {
    this.imgInstance.addEventListener('load', (e: Event | any) => {
      const $img = this.elRef.nativeElement;
      this.instanceLoad.emit({size: {w: e.target.width, h: e.target.height, clientW: $img.clientWidth, clientH: $img.clientHeight}});
      this.imgInstance = new Image();
      this.addListener();
    });
  }
}
