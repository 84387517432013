import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SocialService } from '../../services/social.service';
import { TOrderStatus } from '../../types/Entities';

@Component({
  selector: 'app-b-result',
  templateUrl: './b-result.component.html',
  styleUrls: ['./b-result.component.styl'],
})
export class BResultComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() sub_title: string;
  @Input() status: TOrderStatus;
  @Input() successStatuses: TOrderStatus[] = ['done', 'paid', 'on_delivery'];
  @Input() errorStatuses: TOrderStatus[] = ['refund', 'cancel', 'outdated', 'error'];
  @Input() finalStatuses: TOrderStatus[] = [...this.successStatuses, ...this.errorStatuses];
  public success: boolean;
  public error: boolean;
  public currStatus: 'success' | 'error';

  constructor(public social: SocialService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.currStatus = this.isSuccessStatus()
        ? 'success'
        : this.isErrorStatus()
        ? 'error'
        : undefined;
    }
  }

  isSuccessStatus(): boolean {
    return this.successStatuses.indexOf(this.status) >= 0;
  }

  isErrorStatus(): boolean {
    return this.errorStatuses.indexOf(this.status) >= 0;
  }
}
