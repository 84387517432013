import { Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { InformationModalComponent } from 'src/app/components/new/modals/restaraunt-list-modal/information-modal/information-modal.component';
import { CertificateService } from 'src/app/services/certificate.service';


@Component({
  selector: 'app-single-cert-page',
  templateUrl: './single-cert-page.component.html',
  styleUrls: ['./single-cert-page.component.less']
})
export class SingleCertPageComponent implements OnInit {
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  model = {
    name: '',
    message: '',
    email: '',
    phone: ''
  };

  slides = [
    { name: 'Светлана Алексеева', text: `Отличное место с реально вкусными морепродуктами! Устрицы великолепные, отменный гребешок, вкус пагра запомнился. Правильное место для желающих окунуться в тематическую гастрономию.` },
    { name: 'Кирилл Иванов', text: `Отличное место для любителей морепродуктов! Максимальный выбор и всегда достойное качество блюд! С официантами приятно поболтать на темы блюд.` },
    { name: 'Артем Круглов', text: `Уютно, весело, культурно. Яркие находки в баре, ребята стараются. Отличный выбор морегадов, к ним достойное вино. Рекомендую для ознакомления с морской кухней.` },
    { name: 'Светлана Алексеева', text: `Отличное место с реально вкусными морепродуктами! Устрицы великолепные, отменный гребешок, вкус пагра запомнился. Правильное место для желающих окунуться в тематическую гастрономию.` },
    { name: 'Кирилл Иванов', text: `Отличное место для любителей морепродуктов! Максимальный выбор и всегда достойное качество блюд! С официантами приятно поболтать на темы блюд.` },
    { name: 'Артем Круглов', text: `Уютно, весело, культурно. Яркие находки в баре, ребята стараются. Отличный выбор морегадов, к ним достойное вино. Рекомендую для ознакомления с морской кухней.` },
  ];
  slideConfig1 = { "slidesToShow": 3, "slidesToScroll": 1 };


  slider1 = [
    {
      img: "/assets/new/img/single-cert/slider1.png"
    }
  ];

  addSlide() {

  }


  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  validateEmail(): boolean {
    return true;
  }
  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  constructor(
    public deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    public service: CertificateService,
    private renderer: Renderer2,
  ) {
    if (this.deviceService.isMobile()){
      this.slideConfig1.slidesToShow = 1;
    }

    this.renderer.addClass(document.body, 'zapomny-page-container-single');
  }

  ngOnInit() {
    this.service.getSettings().subscribe(res => {
      localStorage.setItem('x-auth-token', res.headers.get('x-auth-token'));


      this.service.getTags().then(data => {
        this.service.tagList = data;
      }, () => { });

      this.service.getConfig().then(data => {
        this.service.certList = data;
        console.log(this.service.certList)
      }, () => { });
    });

  }

  onSlide(slideEvent: NgbSlideEvent) { }

  openModal() {
    this.modalService.open(InformationModalComponent, { centered: true, windowClass: "cert-example-modal" }).result.then((result) => {

    }, (reason) => {

    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'zapomny-page-container-single')
  }
}
