import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ICertificate, ICertificateSkinSettings, IMediaInfo } from '../../types/Entities';
import { addDays, addHours, addMonths, addYears, parse } from 'date-fns';
import { FsService } from '../../services/fs.service';
import { IImgListenerLoad } from '../../directives/img-listener.directive';
import { Router } from '@angular/router';
const parseInterval = require('postgres-interval');

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.styl']
})
export class GiftCardComponent implements OnInit, OnChanges {
  @Input() gift: ICertificate;
  //@Input() gift?: any;
  @Input() skin?: ICertificateSkinSettings;
  @Input() name?: string;
  @Input() bg?: string;
  @Input() price?: number;
  @Input() sz = '1';
  @Input() logoInput = false;
  @Input() logo?: IMediaInfo;
  @Input() showTable?: boolean;
  @Input() showRelated = true;
  @Input() showRemove = false;
  @Input() theme = '1';
  @Input() pos = '1';
  @Input() url?: string;
  @Input() showRate:boolean = true;
  @Output() changeFiles = new EventEmitter<File>();
  @Output() logoImageLoaded = new EventEmitter<IImgListenerLoad>();
  @Output() removeClick = new EventEmitter<any>();

  @ViewChild('logoInp', { static: false }) logoInp: ElementRef;

  public _logo: IMediaInfo;
  public lastDate: Date | string;
  public rating: number = null;

  constructor(
    public global: GlobalService,
    public fsService: FsService,
    private router: Router) { }

  ngOnInit() {

    this.initRating();

    this.skin = this.skin || (this.gift || {}).skin_settings;
    this._logo = this.logo;

    let d: Date = new Date();
    if (this.gift.activation_last_date) {
      d = new Date(
        this.gift.activation_last_date.replace(/\s/, 'T').replace(/\+.+/, '')
      );
    } else if (this.gift.activation_ttl) {
      const ttl = parseInterval(this.gift.activation_ttl);
      Object.keys(ttl).map(k => {
        switch (k) {
          case 'years':
            d = addYears(d, ttl.years);
            break;
          case 'months':
            d = addMonths(d, ttl.months);
            break;
          case 'days':
            d = addDays(d, ttl.days);
            break;
          case 'hours':
            d = addHours(d, ttl.hours);
            break;
        }
      });
    }
    if (d.getTime() - Date.now() >= 1000 * 60 * 60 * 24 * 30 * 12 * 2) {
      this.lastDate = null;
    } else {
      this.lastDate = d;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.logo) {
      this._logo = this.logo;
    }
  }

  onFileChange(files: File[]) {
    // if (files && files[0]) {
    this.changeImage(files[0]);
    this.changeFiles.emit(files[0]);
    // }
  }

  initRating() {
    if (this.gift.meta.restaurant_gift_type === 'one' && this.gift.Partners && this.gift.Partners.length > 0) {
      const partner = this.gift.Partners.find(el => typeof (el.data.restaurant_rating) !== 'undefined');
      if (!partner) {
        return;
      }

      this.rating = partner.data.restaurant_rating;
    }
  }

  onInputFileChange(e) {
    this.onFileChange(e.target.files);
    e.target.value = '';
  }

  changeImage(file: File) {
    this._logo = {
      path: this.fsService.blobUrlRef(file)
    };
  }

  onLogoRemove() {
    this._logo = this.logo;
    this.logoInp.nativeElement.value = '';
    this.changeFiles.emit(null);
  }

  onPriceClick(price: number) {
    if (this.price === price) {
      this.price = undefined;
    } else {
      this.price = price;
    }
  }

  onContentClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  onClickLink(e: Event) {
    e.preventDefault();
   

    this.router.navigate([this.url], { queryParams: { price: this.price } });


  }


  onBuyClick(e: Event) {
    this.onContentClick(e);
    this.router.navigate([this.url], { queryParams: { price: this.price } });
  }

  onRemoveClick(e: Event) {
    this.onContentClick(e);
    this.removeClick.emit();
  }
}
