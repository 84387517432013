import { Component, OnInit } from '@angular/core';
import { IShowHideWidget, WidgetService } from '../../services/widget.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimationHide, fadeAnimationShow } from '../../animations';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-widget-iframe',
  templateUrl: './widget-iframe.component.html',
  styleUrls: ['./widget-iframe.component.styl'],
  animations: [
    trigger('showAnimationTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, {params: {timings: '400ms ease-in-out'}})
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, {params: {timings: '400ms ease-in-out'}})
      ]),
    ]),
    trigger('showHideIframeAnimationTrigger', [
      transition('* => true', [
          useAnimation(fadeAnimationShow, {params: {timings: '400ms ease-in-out'}})
        ]
      ),
      transition('* => false', [
          useAnimation(fadeAnimationHide, {params: {timings: '400ms ease-in-out'}})
        ]
      )
    ]),
    // trigger('hideIframeAnimationTrigger', [
    //   transition('void => false', [
    //       useAnimation(fadeAnimationHide, {params: {timings: '400ms ease-in-out'}})
    //     ]
    //   )
    // ]),
  ]
})
export class WidgetIframeComponent implements OnInit {
  public state: IShowHideWidget = {show: false};
  public loaded = false;

  constructor(public widget: WidgetService, private global: GlobalService) { }

  ngOnInit() {
    this.widget.showHide$.subscribe(state => {
      this.state = state;
      this.global.toggleBodyOverflow(state.show);
    });
  }

  onIframeLoad() {
    this.loaded = true;
  }

  onClose() {
    this.widget.close();
    this.state = {show: false};
    this.loaded = false;
  }
}
