import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RestarauntListModalComponent } from '../modals/restaraunt-list-modal/restaraunt-list-modal.component';

@Component({
  selector: 'cert-list',
  templateUrl: './cert-list.component.html',
  styleUrls: ['./cert-list.component.less']
})
export class CertListComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    public deviceService: DeviceDetectorService,

  ) { }

  ngOnInit() {
  }

  showAll() {
    this.modalService.open(RestarauntListModalComponent, { centered: true, windowClass: 'restaraunt-list-modal', backdropClass: 'restaraunt-list-backdrop' }).result.then((result) => {

    }, (reason) => {

    });
  }
}
