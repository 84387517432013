import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TCurrency } from '../types/Entities';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(public translate: TranslateService, public global: GlobalService) {}

  /* Возвращает стоимость -- 100.00 */
  transform(price: number, currency?: TCurrency): string {
    const currencies = this.global.prices_currency;
    const result = price / 100;
    return `${result} ${currencies[currency] || currencies[this.global.default_currency]}`;
  }
}
