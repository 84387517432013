import { Component, Input, OnInit } from '@angular/core';
import { ICertificate } from '../../types/Entities';
import { TransportService } from '../../services/transport.service';
import { WidgetService } from '../../services/widget.service';
import { ICertificateActivated } from '../../types/req';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {path} from 'ramda';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-b-activate',
  templateUrl: './b-activate.component.html',
  styleUrls: ['./b-activate.component.styl'],
})
export class BActivateComponent implements OnInit {
  @Input() gifts: ICertificate[];
  @Input() fetchGifts = false;
  @Input() listenActivate = true;
  @Input() md: string;
  @Input() margin = true;
  @Input() theme = '1';
  @Input() text: string;


  public isDesktop = window.innerWidth > 991;
  public activated = false;
  public result: ICertificateActivated;
  public resultDate: string;
  public error: string;
  public fetching = false;

  constructor(
    private api: TransportService,
    private widget: WidgetService,
    private translate: TranslateService,
    private global: GlobalService
  ) {
  }

  ngOnInit() {
    console.log(this.global.settings)
    if (this.fetchGifts) {
      this.api.getGifts().subscribe(gifts => this.gifts = gifts);
    }
  }

  onSubmit() {
    if (this.listenActivate) {
      if (!this.md) return;
      // this.widget.open(this.widgetUrl);
      this.error = undefined;
      this.fetching = true;
      this.api.certificateActivate(this.md)
        .pipe(
          finalize(
            () => {
              this.fetching = false;
            }
          ),
          catchError(
            err => {
              if (err && err.error) {
                const key = path(['message'], err.error);
                if (key) {
                  this.translate.get('ERRORS').subscribe(values => {
                    this.error = values[key];
                  });
                }
              }
              return throwError(err);
            }
          )
        )
        .subscribe(
        data => {
          this.result = data;
          this.activated = true;
          const validity_range = data.validity_range.replace(/[)(\]["]/g, '').split(/\s*,\s*/);
          this.resultDate = validity_range[validity_range.length - 1]
            .replace(/\s/, 'T')
            .replace(/\+.+/, '');
        }
      );
    }
  }

  onEventsClick() {
    this.widget.open();
  }
}
