import {Injectable} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FsService {

  constructor(private sanitizer: DomSanitizer) { }

  base64UrlRef(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        reject();
      }
    });
  }

  blobUrlRef(file: File): string {
    return window.URL.createObjectURL(file);
  }

  makeFilePathForUpload(file: File): string {
    if (!file || !file.name) {
      return;
    }
    const filename = file.name.replace(/[^0-9a-zA-Zа-яА-ЯёЁ\._@]/g, '_').replace(/_{2,}/g, '_').trim();
    const uuid = uuidv4();
    const uuidArray = uuid.split('');
    return `${uuidArray.pop()}${uuidArray.pop()}/${uuidArray.pop()}${uuidArray.pop()}/${uuid}/${filename}`;
  }
}
