import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ICertRequest } from '../interfaces';
import { Observable } from 'rxjs';
import { ICertificate, ISettings } from '../types/Entities';
import { ITag } from '../types/ITag';


@Injectable()
export class CertificateService {
  public docIsOpen:boolean = false;
  private _token: string;

  public tagList: ITag[];
  public certList: ICertificate[];
  
  constructor(
    private http: HttpClient,
  ) { }



  setCert(data: ICertRequest): Promise<any> {
    return this.http.post<any>("https://restaurant.zapomni.gift/api/gift/v2/showcase/appeal", data).toPromise<any>();
  }

  getConfig(): Promise<any> {
    return this.http.get<any>(`/api/gift/v2/partner/showcase/list`).toPromise();
  }

  getTags():Promise<any>{
    return this.http.get<any>(`/api/gift/v2/partner/showcase/tag`).toPromise();
  }

  getSettings(): Observable<HttpResponse<ISettings>> {
    return this.http.get<ISettings>(`/api/gift/v2/partner/showcase/settings`, {observe: 'response'});
  }
  // private _getSettings(): Promise<any> {
  //   return this.http.get(, { observe: 'response' }).toPromise<any>();
  // }

  // private _getEventsList(token: string): Promise<any> {
  //   const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
  //   return this.http.get(, { headers }).toPromise() as Promise<IdateItem[]>;
  // }

  // getEvents(): Promise<any> {
  //   if (!this._token) {
  //     return this._getSettings()
  //       .then(result => {
  //         const token = result.headers.get('x-auth-token');
  //         if (token) {
  //           this._token = token;
  //           window.localStorage.setItem('zapomniToken', token);
  //           return token;
  //         }
  //         throw new Error('get settings error');
  //       })
  //       .then((token) => {
  //         return this._getEventsList(token);
  //       })
  //       .catch(e => {
  //         alert(e);
  //         return e;
  //       });
  //   } else {
  //     return this._getEventsList(this._token);
  //   }
  // }

}



