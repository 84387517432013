import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ICertRequest } from '../interfaces';
import { Observable } from 'rxjs';
import { ICertificate, ISettings } from '../types/Entities';
import { ITag } from '../types/ITag';


@Injectable()
export class QueryBuilderService {
  public tags: ITag[] = [];

  @Output() changeTagsQueryEvent = new EventEmitter<any>();
  constructor(

  ) { }

  fireChangeTagsQuery() {
    const query = {
      tagList: []
    };

    if (this.tags && this.tags.length > 0) {
      query.tagList = this.tags;
    }

    

    this.changeTagsQueryEvent.emit(query);
  }


}



