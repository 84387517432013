import { Component, OnInit } from '@angular/core';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.less']
})
export class SectionItemComponent implements OnInit {
  isShown:boolean = false;
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, };
  slides = [
    {
      img: "/assets/new/img/slider/slider1/1.png"
    },
    {
      img: "/assets/new/img/slider/slider1/1.png"
    },
    {
      img: "/assets/new/img/slider/slider1/1.png"
    }
  ];
  constructor() { }

  ngOnInit() {
  }

  toggle(){
    this.isShown = !this.isShown;
  }

}
