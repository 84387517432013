import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  TabsTabContentTemplateDirective,
  TabsTabIconTemplateDirective,
} from '../../../modules/tabs/tabs-templates.directive';

@Component({
  selector: 'app-tabs-tab',
  templateUrl: './tabs-tab.component.html',
  styleUrls: ['./tabs-tab.component.styl'],
})
export class TabsTabComponent {
  @Input() show$ = new BehaviorSubject<boolean>(false);
  @Input() url: string;
  @Input('tabTitle') title: string;
  @Input() data: any;
  @Input() uuid = 'tab';
  @Input() gap = 'none';
  @Output() showHide: EventEmitter<any> = new EventEmitter();
  @Output() hide: EventEmitter<any> = new EventEmitter();
  @Output() show: EventEmitter<any> = new EventEmitter();

  @ContentChild(TabsTabContentTemplateDirective, { read: TemplateRef, static: true })
  tabContentTemplate: TemplateRef<any>;
  @ContentChild(TabsTabIconTemplateDirective, { read: TemplateRef, static: true })
  tabIconTemplate: TemplateRef<any>;

  constructor(public el: ElementRef) {
  }
}
