import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {ICertificateSkinSettings} from '../../types/Entities';
import { ResizedEvent } from 'angular-resize-event';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {
  @Input() showLogo = false;
  @Input() sz = '1';
  @Input() theme = '1';
  @Input() pos = '1';
  @Input() skin: ICertificateSkinSettings;
  @Input() hash_tag: string;
  @Input() customLogo: string;
  @Input() customLogoSz: string;
  @Input() showCompanyLogo = false;


  @ViewChild('logoContainer', {static: false}) logoContainer: ElementRef;


  public timeout;
  public resized: ResizedEvent;
  public height: number;
  public headerLogo: {img?: string, url?: string, svg?: string};

  constructor(
    private global: GlobalService
  ) { }

  ngOnInit() {
    if (this.showCompanyLogo) {
      this.headerLogo = this.global.settings.widget_settings.header_logo;
    }
  }

  onResized(e: ResizedEvent) {
    this.resized = e;
    this.height = this.resized.newHeight;
    if (this.logoContainer && this.logoContainer.nativeElement) {
      const $logoContainer = this.logoContainer.nativeElement;
      if ($logoContainer) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.height = $logoContainer.clientHeight;
        }, 200);
      }
    }
  }
}
