import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './modules/shared/shared.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {CustomErrorHandlerService} from '../error-handler';
// import {registerLocaleData} from '@angular/common';
// import localeRu from '@angular/common/locales/ru';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {TransferHttpCacheModule} from '@nguniversal/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {CertListComponent} from 'src/app/components/new/cert-list/cert-list.component';
import {FilterByCookComponent} from 'src/app/components/new/filters/filter-by-cook/filter-by-cook.component';
import {RestarauntListModalComponent} from 'src/app/components/new/modals/restaraunt-list-modal/restaraunt-list-modal.component';
import {InformationModalComponent} from 'src/app/components/new/modals/restaraunt-list-modal/information-modal/information-modal.component';
import {FooterComponent} from 'src/app/components/new/views/footer/footer.component';
import {HeaderComponent} from 'src/app/components/new/views/header/header.component';
import {SectionItemComponent} from 'src/app/components/new/views/section-item/section-item.component';
import {SectionItemSingleCertComponent} from 'src/app/components/new/views/section-item-single-cert/section-item-single-cert.component';
import {TabsComponent} from 'src/app/components/new/views/tabs/tabs.component';
import { CertificateService } from './services/certificate.service';
import { QueryBuilderService } from './services/query-builder.service';
import {IndexPageComponent} from 'src/app/pages/new/index-page/index-page.component';
import {SingleCertPageComponent} from 'src/app/pages/new/single-cert-page/single-cert-page.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { WrenchPageComponent } from './pages/new/wrench-page/wrench-page.component';
// registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    CertListComponent,
    FilterByCookComponent,
    RestarauntListModalComponent,
    InformationModalComponent,
    FooterComponent,
    HeaderComponent,
    SectionItemComponent,
    SectionItemSingleCertComponent,
    TabsComponent,
    IndexPageComponent,
    SingleCertPageComponent,
    WrenchPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, '/assets/i18n/');
        },
        deps: [HttpClient],
      },
      isolate: true,
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    CertificateService,
    QueryBuilderService

    // {
    //   provide: ErrorHandler, useClass: CustomErrorHandlerService
    // },
    // {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  entryComponents: [
    RestarauntListModalComponent,
    InformationModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
