import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalService } from './global.service';


export interface IShowHideWidget {
  url?: string;
  show: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  public showHide$: Subject<IShowHideWidget> = new Subject<IShowHideWidget>();

  constructor(
    private global: GlobalService
  ) { }

  open(url?: string) {
    const _url = url || `${location.origin}/widget/events`;
    // if (this.global.isDesktop) {
    //   this.showHide$.next({url: _url, show: true});
    // } else {
    //   window.open(`${_url}`);
    // }
    window.open(`${_url}`);
  }

  close() {
    this.showHide$.next({show: false});
  }
}
