import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollModule } from '../scroll/scroll.module';
import {
  TabsTabContentTemplateDirective,
  TabsTabIconTemplateDirective,
} from './tabs-templates.directive';
import {TabsComponent} from '../../components/tabs/tabs.component';
import {TabsTabComponent} from '../../components/tabs/tabs-tab/tabs-tab.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TabsComponent,
    TabsTabComponent,
    TabsTabIconTemplateDirective,
    TabsTabContentTemplateDirective,
  ],
  exports: [
    TabsComponent,
    TabsTabComponent,
    TabsTabIconTemplateDirective,
    TabsTabContentTemplateDirective,
  ],
  imports: [CommonModule, ScrollModule, RouterModule],
})
export class TabsModule {}
