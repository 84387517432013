import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.less']
})
export class InformationModalComponent implements OnInit {
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  slides = [
    { url: 'assets/img/single-cert/example-1.png' },
    { url: 'assets/img/single-cert/example-2.png' },
    { url: 'assets/img/single-cert/example-3.png' }
  ];

  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };
  ngOnInit() {
  }
  close() {
    this.activeModal.close();
  }
}
