import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransportService } from './services/transport.service';
import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {isPlatformBrowser} from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
})
export class AppComponent implements AfterViewInit{
  private isBrowser: boolean;
  private noScrollRoutes = ['/', '/activate'];
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private translate: TranslateService,
    private api: TransportService,
    public auth: AuthService,
    public global: GlobalService,
    private device: DeviceDetectorService,
    private router: Router,
    private meta: Meta
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(
      () => {
        if (!location.hash && !this.noScrollRoutes.find(route => route === this.router.url)) {
          if (this.isBrowser) {
            window.scrollTo(0, 0);
          }
        }
      }
    );
    translate.setDefaultLang('ru');
    translate.use('ru');
    this.auth.init();
    this.api.getSettings().subscribe(res => {
      const s = res.body;
      this.global.setSettings(s);
      if (s.widget_settings.origin_url) {
        this.meta.updateTag({
          property: 'og:url', content: s.widget_settings.origin_url
        });
      }
      this.noScrollRoutes = res.body.widget_settings.noScrollRoutes || this.noScrollRoutes;
      this.auth.isLoggedIn().subscribe(isLoggedIn => {
        if (!isLoggedIn) {
          this.auth.saveToken(res.headers.get('x-auth-token'));
          this.auth.init();
        }
      });
    });
    const deviceInfo = this.device.getDeviceInfo();
    const isDesktop = this.device.isDesktop();
    this.global.setDevice(deviceInfo, isDesktop);
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   const hash = location.hash || '';
    //   if (hash.length) {
    //     const $el: HTMLElement = document.querySelector(`${hash}`);
    //     if ($el) {
    //       window.scrollTo(0, $el.offsetTop);
    //     }
    //   }
    // }, 200);
  }
}
