import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricesSort'
})
export class PricesSortPipe implements PipeTransform {

  transform(value: number[], ...args: any[]): any {
    if (value && value.length) {
      return [...value].sort((a, b) => b - a);
    }
    return null;
  }
}
