import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { get as getCookie, set as setCookie } from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private data = {};
  private isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  setItem(key, val, local = false) {
    if (this.isBrowser) {
      if (local) {
        this.data[key] = val;
        return;
      }
      try {
        localStorage.setItem(key, val);
      } catch (e) {
        this.data[key] = val;
      }
    }
  }

  removeItem(key, local = false) {
    if (this.isBrowser) {
      if (local) {
        delete this.data[key];
        return;
      }
      try {
        localStorage.removeItem(key);
      } catch (e) {
        delete this.data[key];
      }
    }
  }

  getItem(key, local = false) {
    if (this.isBrowser) {
      if (local) {
        return this.data[key];
      }
      let val;
      try {
        val = localStorage.getItem(key);
      } catch (e) {
        val = this.data[key];
      }
      return val;
    }
  }

  setCookie(key: string, value: string, opt?: any) {
    if (this.isBrowser) {
      setCookie(key, value, opt);
    }
  }

  getCookie(key: string): string {
    if (this.isBrowser) {
      return getCookie(key);
    }
  }
}
