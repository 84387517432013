import { Component, OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CertificateService } from 'src/app/services/certificate.service';

@Component({
  selector: 'app-restaraunt-list-modal',
  templateUrl: './restaraunt-list-modal.component.html',
  styleUrls: ['./restaraunt-list-modal.component.less']
})
export class RestarauntListModalComponent implements OnInit {
  isCard=false;
  constructor(
    public activeModal: NgbActiveModal,
    public service: CertificateService
  ) { }

  ngOnInit() {
  }

  close(){
    this.activeModal.close();
  }

}
