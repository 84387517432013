
import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SlickCarouselComponent } from 'ngx-slick-carousel';


@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class IndexPageComponent implements OnInit {
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  model = {
    name: '',
    message: '',
    email: '',
    phone: ''
  };

  slides = [
    { name: 'Светлана Алексеева', text: `Отличное место с реально вкусными морепродуктами! Устрицы великолепные, отменный гребешок, вкус пагра запомнился. Правильное место для желающих окунуться в тематическую гастрономию.` },
    { name: 'Кирилл Иванов', text: `Отличное место для любителей морепродуктов! Максимальный выбор и всегда достойное качество блюд! С официантами приятно поболтать на темы блюд.` },
    { name: 'Артем Круглов', text: `Уютно, весело, культурно. Яркие находки в баре, ребята стараются. Отличный выбор морегадов, к ним достойное вино. Рекомендую для ознакомления с морской кухней.` },
    { name: 'Светлана Алексеева', text: `Отличное место с реально вкусными морепродуктами! Устрицы великолепные, отменный гребешок, вкус пагра запомнился. Правильное место для желающих окунуться в тематическую гастрономию.` },
    { name: 'Кирилл Иванов', text: `Отличное место для любителей морепродуктов! Максимальный выбор и всегда достойное качество блюд! С официантами приятно поболтать на темы блюд.` },
    { name: 'Артем Круглов', text: `Уютно, весело, культурно. Яркие находки в баре, ребята стараются. Отличный выбор морегадов, к ним достойное вино. Рекомендую для ознакомления с морской кухней.` },
  ];
  slideConfig1 = { "slidesToShow": 3, "slidesToScroll": 1 };
  

  slider1 = [
    {
      img: "/assets/new/img/slider1.png"
    }
  ];



  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  validateEmail(): boolean {
    return true;
  }
  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  constructor(
    private deviceService: DeviceDetectorService
  ) {
    if(this.deviceService.isMobile())
      this.slideConfig1.slidesToShow = 1;
   }

  ngOnInit() {

  }

  onSlide(slideEvent: NgbSlideEvent) { }
}
