import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalService } from './global.service';
import { IMultiLangField } from '../types/Entities';

export interface IDocs {
  gift_offer?: IMultiLangField;
  offer?: IMultiLangField;
}

export interface IDocData {
  src?: IMultiLangField;
}

@Injectable({
  providedIn: 'root'
})
export class DocService {
  private showHidePopupSource = new Subject<boolean>();
  public showHidePopup = this.showHidePopupSource.asObservable();
  public data: IDocData = {};
  public docs: IDocs = {};

  constructor(private global: GlobalService) {
    if (global.settings) {
      this.init();
    } else {
      global.settings$.subscribe(s => {
        this.init();
      });
    }
  }

  init() {
    this.docs.gift_offer = this.global.settings.gift_offer;
    this.docs.offer = this.global.settings.offer;
  }

  public openPopup(name: keyof IDocs) {
    this.data.src = this.docs[name];
    this.global.toggleBodyOverflow(true);
    this.showHidePopupSource.next(true);
  }

  public closePopup() {
    this.global.toggleBodyOverflow(false);
    this.showHidePopupSource.next(false);
  }
}
