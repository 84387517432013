import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoggerService} from '../services/logger.service';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isBrowser: boolean;

  constructor(
    private logger: LoggerService,
    @Optional() @Inject('serverUrl') protected serverUrl: string,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (!this.isBrowser) {
    //   console.log('REQUEST', request.url);
    //   console.log('REQUEST SERVER URL', this.serverUrl);
    // }
    return next.handle(request).pipe(
      catchError(err => {
        // console.log('REQUEST ERROR', request.url);
        // console.log('REQUEST SERVER URL', this.serverUrl);
        return throwError(this.isBrowser ? err : (err || {}).message);
      })
    );
  }
}
