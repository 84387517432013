import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ICertificateSkinSettings } from '../../types/Entities';

@Component({
  selector: 'app-layout-gift',
  templateUrl: './layout-gift.component.html',
  styleUrls: ['./layout-gift.component.styl'],
})
export class LayoutGiftComponent implements OnInit, OnChanges {
  @Input() theme = '1';
  @Input() skin: ICertificateSkinSettings = {skin: 'default'};
  @Input() bg_theme = '1';
  @Input() bg: string;
  @Input() fee: {value: string, text: string};
  @Input() hash_tag: string;
  @Input() customLogo: string;
  @Input() customLogoSz: string;
  @Input() showHeaderCompanyLogo = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
  }
}
