import { Component, OnInit } from '@angular/core';
import {IMultiLangField} from '../../../types/Entities';
import { GlobalService } from '../../../services/global.service';

export interface INav {
  name: IMultiLangField;
  route: string;
}

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.styl']
})
export class HeaderNavComponent implements OnInit {
  public nav: INav[] = [
    {
      name: {ru: 'Что такое Zapomni.Gift?'},
      route: ''
    },
    {
      name: {ru: 'Как подарить?'},
      route: ''
    },
    {
      name: {ru: 'Контакты'},
      route: ''
    }
  ];
  public showNav = false;

  constructor(
    private global: GlobalService
  ) { }

  ngOnInit() {
    const settings = this.global.settings.widget_settings;
    // this.nav = settings.nav || this.nav;
    // if (settings.hide_routes) {
    //   this.nav = this.nav.filter(item => !settings.hide_routes.find(route => route === item.route));
    // }
  }
}
