import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { SocialService } from '../../../../services/social.service';
import * as $ from 'jquery';
@Component({
  selector: 'new-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  public showNav = false;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  // @HostListener('document:click', ['$event'])
  // onClick(event: Event) {
  //   if (this.menu && !this.menu.nativeElement.parentNode.contains(event.target)) {
  //     $('.main-header--mobile .header__menu-container .header-menu').removeClass('active')
  //   }
  // }
 

  constructor(
    public social: SocialService,
  ) { }

  ngOnInit() {
  }

}
