import { Component, OnInit } from '@angular/core';
import { CertificateService } from 'src/app/services/certificate.service';
import { QueryBuilderService } from 'src/app/services/query-builder.service';
import { ITag } from 'src/app/types/ITag';

@Component({
  selector: 'filter-by-cook',
  templateUrl: './filter-by-cook.component.html',
  styleUrls: ['./filter-by-cook.component.less']
})
export class FilterByCookComponent implements OnInit {
  getAll: boolean = true;
  constructor(
    public service: CertificateService,
    public queryService: QueryBuilderService
  ) { }

  ngOnInit() {

  }

  toggleAll() {
    this.getAll = !this.getAll;
    if (this.getAll) {
      this.queryService.tags = [];
      this.queryService.fireChangeTagsQuery();
    }
  }
  toggle(tag: ITag) {
    // const pos = this.queryService.tags.findIndex(id => tag._uuid === id);
    // if (pos > -1) {
    //   this.queryService.tags.splice(pos, 1);
    // } else {
    //   this.queryService.tags.push(tag);
    // }

    // if (this.queryService.tags.length < 1) {
    //   this.getAll = true;
    // } else {
    //   this.getAll = false;
    // }

    this.queryService.fireChangeTagsQuery();
  }

  isActive(uid: string): boolean {
    // return this.queryService.tags.findIndex(id => uid === id) > -1;
    return true; 
  }
}
