import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { DeviceInfo } from 'ngx-device-detector';
import { HttpResponse } from '@angular/common/http';
import { ISettings, IWidgetSettings } from '../types/Entities';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public settings$: Subject<ISettings> = new Subject();
  public deviceInfo: DeviceInfo;
  public deviceType: string;
  public isDesktop: boolean;
  public settings: ISettings;
  public widget_settings: IWidgetSettings;
  public readonly prices_currency = {
    // rur: '₽',
    // rub: '₽',
    rur: 'р',
    rub: 'р',
    usd: '$',
    eur: '€',
    amd: '֏',
  };
  public readonly default_currency = 'rub';

  constructor() {}

  public setSettings(s: ISettings) {
    this.settings = s;
    this.widget_settings = s.widget_settings;
    this.settings$.next(s);
  }

  public setDevice(info: DeviceInfo, isDesktop: boolean) {
    this.deviceInfo = info;
    this.deviceType = isDesktop ? 'desktop' : 'mobile';
    this.isDesktop = isDesktop;
  }

  toggleBodyOverflow(set: boolean) {
    const html = document.querySelector('html');
    if (set) {
      html.classList.add('ovh');
    } else {
      html.classList.remove('ovh');
    }
  }
}
