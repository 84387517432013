import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICertificate } from 'src/app/types/Entities';

@Component({
  selector: 'app-company-heading',
  templateUrl: './company-heading.component.html',
  styleUrls: ['./company-heading.component.styl'],
})
export class CompanyHeadingComponent implements OnInit {
  @Input() showLogo = true;
  @Input() banner: ICertificate = null;

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  onClickLink() {
    const link = this.banner.user_friendly_url ? this.banner.user_friendly_url : this.banner._uuid;
    if (link) {
      this.router.navigate(['/' + link])
    }

  }
}
