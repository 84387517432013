import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { is } from 'ramda';

@Pipe({
  name: 'multilang',
  pure: false,
})
export class MultilangFieldPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(k: any): string {
    const lang = this.translate.currentLang;
    if (is(Object, k)) {
      const val = k[lang];
      // return propOr(k['ru'], lang, k);
      return val && typeof val === 'string' && val.length > 0 ? val : k['ru'];
    } else {
      return k;
    }
  }
}
