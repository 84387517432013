import {AfterViewInit, Directive, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[appViewInit]'
})
export class ViewInitDirective implements AfterViewInit {
  @Output() viewInit: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngAfterViewInit(): void {
    this.viewInit.emit();
  }
}
