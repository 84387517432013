import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-form-helper',
  templateUrl: './form-helper.component.html',
  styleUrls: ['./form-helper.component.styl']
})
export class FormHelperComponent implements OnInit {
  public fhFocusedElement: EventTarget;
  private blurTimer;
  private focusTimer;
  private debounceTime = 10;

  constructor() { }

  ngOnInit() {
  }

  fhOnInputFocus(e: Event, input?: NgModel) {
    clearTimeout(this.focusTimer);
    this.focusTimer = setTimeout(() => {
      this.fhFocusedElement = e.target;
      // input.control.markAsDirty();
    }, this.debounceTime);
  }

  fhOnInputBlur(e?: Event) {
    clearTimeout(this.blurTimer);
    this.blurTimer = setTimeout(() => {
      this.fhFocusedElement = null;
    }, this.debounceTime);
  }
}
