import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TransportService } from '../../services/transport.service';
import { NgForm } from '@angular/forms';
import { IAppealData } from '../../types/req';
import { finalize } from 'rxjs/operators';
import { FormService } from '../../services/form.service';
import { ICertificateSkinSettings } from '../../types/Entities';
import { FormHelperComponent } from '../form-helper/form-helper.component';

@Component({
  selector: 'app-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.styl'],
})
export class SubscribeFormComponent extends FormHelperComponent implements OnInit, OnChanges {
  @Input() skin: ICertificateSkinSettings = {skin: 'special'};
  @Input() title: string;
  @Input() theme = '1';
  @Input() showPhone = true;
  @Input() showMessage = true;
  @Input() showCompany = true;
  @Input() messageRequired = true;

  @ViewChild('form', { static: true }) _form: NgForm;

  public md: IAppealData = {
    type: 'partner',
    name: '',
    phone: '',
    email: '',
    message: ''
  } as IAppealData;
  public fetching = false;
  public success = false;
  public formSubmitted = false;

  constructor(private api: TransportService, private formService: FormService) { super(); }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showMessage) {
      this.md.type = this.showMessage ? 'client' : 'partner';
    }
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this._form);
    this.formSubmitted = true;
    if (this._form.valid) {
      this.fetching = true;
      this.api
        .appeal(this.md)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.fetching = false;
            }, 200);
          })
        )
        .subscribe(() => {
          this.success = true;
          this._form.reset();
        });
    }
  }
}
