import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslateService, TranslateStore} from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { TransportService } from '../../services/transport.service';
import { GlobalService } from '../../services/global.service';
import { LoggerService } from '../../services/logger.service';
import { StorageService } from '../../services/storage.service';
import { AuthInterceptor } from '../../interceptors/auth-interceptor';
import { LayoutGiftComponent } from '../../components/layout-gift/layout-gift.component';
import { CompanyHeadingComponent } from '../../components/company-heading/company-heading.component';
import {AngularSvgIconModule, SvgHttpLoader, SvgLoader} from 'angular-svg-icon';
import { TabsModule } from '../tabs/tabs.module';
import { MultilangFieldPipe } from '../../pipes/multilang_field.pipe';
import { MoneyPipe } from '../../pipes/money.pipe';
import { ScrollModule } from '../scroll/scroll.module';
import { RouterModule } from '@angular/router';
import { PlusMinusComponent } from '../../components/plus-minus/plus-minus.component';
import { NumerativePipe } from '../../pipes/numerative.pipe';
import { DropFilesDirective } from '../../directives/drop-files.directive';
import { FsService } from '../../services/fs.service';
import { SafePipe } from '../../pipes/safe.pipe';
import { GiftCardComponent } from '../../components/gift-card/gift-card.component';
import { SocialService } from '../../services/social.service';
import { FormService } from '../../services/form.service';
import { BResultComponent } from '../../components/b-result/b-result.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { PricesSortPipe } from '../../pipes/prices-sort.pipe';
import { MetricService } from '../../services/metric.service';
import { ViewInitDirective } from '../../directives/view-init.directive';
import { ValidatorsModule } from '../../validators/validators.module';
import { FormsModule } from '@angular/forms';
import { ImgListenerDirective } from '../../directives/img-listener.directive';
import { SubscribeFormComponent } from '../../components/subscribe-form/subscribe-form.component';
import { DocComponent } from '../../components/doc/doc.component';
import { DocService } from '../../services/doc.service';
import { AngularResizedEventModule } from 'angular-resize-event';
import { StatusMessageComponent } from '../../components/status-message/status-message.component';
import { FormHelperComponent } from '../../components/form-helper/form-helper.component';
import {LayoutComponent} from '../../components/layout/layout.component';
import {FooterComponent} from '../../components/footer/footer.component';
import {HeaderComponent} from '../../components/header/header.component';
import {HeaderNavComponent} from '../../components/header/header-nav/header-nav.component';
import {HeaderShareComponent} from '../../components/header/header-share/header-share.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from '../../interceptors/error-interceptor';
import {PlatformService} from '../../services/platform.service';
import { BActivateComponent } from '../../components/b-activate/b-activate.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WidgetIframeComponent } from '../../components/widget-iframe/widget-iframe.component';
import { WidgetService } from '../../services/widget.service';

const declarations = [
  LayoutComponent,
  LayoutGiftComponent,
  FooterComponent,
  CompanyHeadingComponent,
  PlusMinusComponent,
  GiftCardComponent,
  BResultComponent,
  SubscribeFormComponent,
  DocComponent,
  HeaderComponent,
  HeaderNavComponent,
  HeaderShareComponent,
  StatusMessageComponent,
  FormHelperComponent,
  BActivateComponent,
  WidgetIframeComponent,
  MultilangFieldPipe,
  NumerativePipe,
  SafePipe,
  PricesSortPipe,
  DropFilesDirective,
  ClickOutsideDirective,
  ViewInitDirective,
  ImgListenerDirective
];


@NgModule({
  declarations: [
    declarations,
    MoneyPipe
  ],
  exports: [
    declarations,
    MoneyPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    // BrowserTransferStateModule,
    TranslateModule,
    AngularSvgIconModule,
    TabsModule,
    ScrollModule,
    ValidatorsModule,
    FormsModule,
    AngularResizedEventModule,
    SwiperModule
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        PlatformService,
        TranslateService,
        TranslateStore,
        TransportService,
        GlobalService,
        LoggerService,
        StorageService,
        AuthService,
        FsService,
        SocialService,
        FormService,
        MetricService,
        DocService,
        WidgetService
      ],
    };
  }
}
