import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  public init$: Subject<boolean> = new BehaviorSubject(false);

  constructor(private storage: StorageService) {
  }

  public isLoggedIn(): Observable<boolean> {
    return of(!!this.storage.getItem('x-auth-token'));
  }

  public saveToken(token: string) {
    this.token = token;
    this.storage.setItem('x-auth-token', token);
  }

  public init() {
    this.token = this.storage.getItem('x-auth-token') || this.token;
    if (this.token) {
      this.init$.next(true);
    }
  }
}
