import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexPageComponent } from 'src/app/pages/new/index-page/index-page.component';
import { SingleCertPageComponent } from 'src/app/pages/new/single-cert-page/single-cert-page.component';

const routes: Routes = [
  // { path: 'single-cert', component:SingleCertPageComponent },
  { path: 'index-cert', component: IndexPageComponent },
  { path: '', loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule) },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: 'how', loadChildren: () => import('./pages/how/how.module').then(m => m.HowModule) },
  { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
  { path: 'activate', loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule) },
  { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'subscribe', loadChildren: () => import('./pages/subscribe/subscribe.module').then(m => m.SubscribeModule) },
  { path: 'result/:order_uuid/cert/:lang', loadChildren: () => import('./pages/result/result.module').then(m => m.ResultModule) },
  { path: 'result/:order_uuid/cert', loadChildren: () => import('./pages/result/result.module').then(m => m.ResultModule) },
  { path: 'corona', loadChildren: () => import('./pages/special/special.module').then(m => m.SpecialModule) },
  { path: 'parking', loadChildren: () => import('./pages/parking/parking.module').then(m => m.ParkingModule) },
  { path: 'spasibodoc', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule) },
  { path: 'support', loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule) },
  { path: 'contacts', loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule) },
 
  { path: ':uuid', loadChildren: () => import('./pages/cert-index/index-page.module').then(m => m.IndexPageModule) },
 
  { path: '**', redirectTo: 'spasibodoc' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled',
    useHash: false
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
