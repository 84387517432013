import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ICertificate, IPaymentMethod, ISettings } from '../types/Entities';
import { ITag } from '../types/ITag';
import { IAppealData, ICertificateActivated, ICertificateRes, IShowcaseOrder } from '../types/req';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';

const GIFTS_KEY = makeStateKey('gifts');
const SETTINGS_KEY = makeStateKey('settings');

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  private _gift = 'gift/v2';
  private _widget = 'widget/v1';
  private _origin: string;
  private isBrowser: boolean;
  private gifts: ICertificate[];
  private settings: HttpResponse<ISettings>;

  constructor(
    private http: HttpClient,
    private state: TransferState,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    // this._origin = !this.isBrowser || environment.production ? this.isBrowser ? location.origin : 'https://zapomni.gift' : '';
    this._origin = '';
  }

  getSettings(): Observable<HttpResponse<ISettings>> {
    // this.settings = this.state.get(SETTINGS_KEY, null as any);
    return this.http.get<ISettings>(`${this._origin}/api/gift/v2/partner/showcase/settings`, {observe: 'response'});
      // .pipe(
      //   tap(
      //     s => {
      //       this.settings = s;
      //       // this.state.set(SETTINGS_KEY, this.settings);
      //       return of(s);
      //     }
      //   )
      // );
  }

  getGifts(): Observable<ICertificate[]> {
    this.gifts = this.state.get(GIFTS_KEY, null as any);
    return this.gifts ? of(this.gifts) :
      this.http.get<ICertificate[]>(`${this._origin}/api/gift/v2/partner/showcase/list`).pipe(
        tap(
          gifts => {
            this.gifts = gifts;
            this.state.set(GIFTS_KEY, this.gifts);
            return of(gifts);
          }
        )
      );
  }

  
  
  getTags(): Observable<ITag[]> {
    return this.http.get<ITag[]>(`${this._origin}/api/gift/v2/partner/showcase/tag`);
  }

  getGift(uuid: string): Observable<ICertificate> {
    return this.http.get<ICertificate>(`${this._origin}/api/gift/v2/partner/showcase/list/${uuid}`);
  }


  getParner(uuid: string): Observable<ICertificate> {
    return this.http.get<ICertificate>(`${this._origin}/api/gift/v2/partner/showcase/partner/${uuid}`);
  }


  getGiftPartners(data: {gift_uuid: string}): Observable<any[]> {
    return of(
      new Array(25).fill({}).map((item, i) => {
        return {
          uuid: i,
          media: {path: `http://placekitten.com/${200 + i}/${120 + i}`}
        };
      })
    ).pipe(delay(1000));
  }

  getGiftPaymentMethod(): Observable<IPaymentMethod[]> {
    return this.http.get<IPaymentMethod[]>(`${this._origin}/api/gift/v2/partner/showcase/payment_method`);
  }

  validateMerchant(payment_method_uuid, data): Observable<any> {
    return this.http.post(`${this._origin}/api/gift/v2/partner/showcase/payment_method/${payment_method_uuid}/merchant_validation`, data);
  }

  newOrder(data: string): Observable<ICertificateRes> {
    return this.http.put<ICertificateRes>(`${this._origin}/api/gift/v2/partner/showcase/cart`, data);
  }

  showcaseOrder(uuid: string): Observable<IShowcaseOrder> {
    return this.http.get<IShowcaseOrder>(`${this._origin}/api/gift/v2/partner/showcase/order/${uuid}`);
  }

  uploadFile(data: {filePath: string, file: File}): Observable<any> {
    return this.http.put<any>(`${this._origin}/gift_upload/${data.filePath}`, data.file);
  }

  appeal(data: IAppealData): Observable<any> {
    return this.http.post(`${this._origin}/api/gift/v2/partner/showcase/appeal`, data);
  }

  public certificateActivate(id): Observable<ICertificateActivated> {
    //http://le.lastick.xd.unitix.cloud/widget/v1/certificate/activate
    //{"number": TBH104193}
    return this.http.post<ICertificateActivated>(`${this._origin}/api/gift/v2/partner/certificate/activate`, { number: id });
    // return this.secureApi.apiRunner('POST', 'certificate/activate', { number: id });
  }
}
