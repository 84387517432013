import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private storage: StorageService) {}

  markFormGroupTouched(form: NgForm, dirty = true, focus = true, invalidControl?) {
    (Object as any).values(form.controls).forEach((control: FormControl | any) => {
      control.markAsTouched();

      if (dirty) {
        control.markAsDirty();
      }
      if (focus && control.nativeElement && !control.valid && !invalidControl) {
        control.nativeElement.focus();
        invalidControl = control;
      }
      if (control.controls) {
        this.markFormGroupTouched(control, dirty, focus, invalidControl);
      }
      control.updateValueAndValidity();
    });
  }

  saveFormValues(name: string, values: {} = {}) {
    this.storage.setItem(name, JSON.stringify(values));
  }

  getFormValues(name: string) {
    const v = this.storage.getItem(name);
    return v ? JSON.parse(v) : {};
  }
}
